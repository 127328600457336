<template>
  <div class="container">
    <!-- <div>
      <not-found-info :item="filteredDisplayedAdsForSupplier" :title="'İlan'" :emptyName="'advert'" />
   


    </div> -->
    <div>
      <div class="mb-3">
        <div class="row">
          <div class="col-lg-4 col-6" style="margin-top: 13px;">
          <el-input class="form-control" v-model="filters.SearchTerm" placeholder="Arama Yap..." clearable></el-input>
        </div>
      
      

        </div>
     

      </div>
    
      <not-found-info :item="filteredDisplayedAdsForCarrier" :title="'İlan'" :emptyName="'advert'" />
 
  
        
     <div v-for="(item, index) in filteredDisplayedAdsForCarrier" :key="index">
      <div
    class="row cursor-pointer"
    
  >
    <div class="mb-3">
      <div class="ad">
        <div class="adDetails">
          <div class="d-flex justify-content-center align-items-center">
            <div>
              <span style="    font-size: 15px;"> #{{ item.AdvertNo }} </span>
            </div>
            <div class="text-center hasOffer" v-if="item.AdvertNo > 0">
              <span @click="advertsDialog = true" style="font-size: 12px">
                {{ $moment(item ? item.CreatedDateTime : null)
                .format("LLL") }}
          
              </span>
            </div>
          
          </div>
          
          <span>
            <div class="d-flex">
             
              <div>
             
             
           
                <div class="d-inline">
                  <span v-html="item.ContentHtml" style="    font-size: 15px;"
                    >
                 
                    </span
                  >
                </div>
              </div>
            </div>
          </span>
       
        </div>

     
      </div>
    </div>
    <div class="mb-3" v-if="index % 3 == 0">
      <ins class="adsbygoogle"
     style="display:block"
     data-ad-format="fluid"
     data-ad-layout-key="-fb+5w+4e-db+86"
     data-ad-client="ca-pub-2915546448125125"
     data-ad-slot="9099951584"></ins>


</div>
  </div>
     </div>
     <div class="row mt-3 mb-3" v-if="!isNotGuest">
      <div class="col-12" style="display: flex;justify-content: space-around;">
        <el-button @click="showLogin()" class="el-button bg-primary text-white p-3 mt-3 mb-3 border-radius d-md-block el-button--default" >Daha Fazla Hızlı İlan Görüntülemek İçin Tıklayınız</el-button>
      </div>
     </div>

    </div>
  </div>
</template>

<script>
import { lazyDispatch } from "../../helpers/generalHelper";
import { mapGetters } from "vuex";
import NotFoundInfo from "../../components/notFound/notFoundInfo.vue";
import META_DATA from "../../constants/metaData";

export default {
  metaInfo() {
    return {
      title: META_DATA.FASTADS.Title,
    };
  },
  components: {
    NotFoundInfo,
  },
  data() {
    return {
      value1: 0,
      VehicleTypeSelected:"",
      isNotGuest:false,
      filters: {
        AdvertId: null,
        WarehouseCityId: null,
        WarehouseLat: null,
        WarehouseLon: null,
        DeliveryCompanyCityId: null,
        DeliveryCompanyLat: null,
        VehicleType:null,
        DeliveryCompanyLon: null,
        FilterMe: false,
        IsFavorited: true,
        Skip: 0,
        Take: 100,
        SearchTerm: "",
      },
  
      page: 1,
      perPage: 100,
      pages: [],
      maxSliderValue: 2000,
      showDistanceFilter: false,
    };
  },
  mounted () {
    this.adsenseAddLoad();
},
   created() {
    // const user = JSON.parse(JSON.stringify(this.user));
    // const query = this.$route.query;

    // const setIsCarrier = async (bool) => {
    //   console.log("isCarrier " + bool);
    //   this.$store.commit("setIsCarrier", bool);
    // };

    // set guest role if guest
    // if (!user.ID){
    //    await setIsCarrier(query.misafirRol === "nakliyeci");
    // }else{
    //   this.isNotGuest = true;
    // }
    this.$store.commit('toggleLoginDialog', false)
   this.$store.dispatch("getFastAdverts", this.filters);

  },
  computed: {
    ...mapGetters({
      fastAds: "fastAdvertsDetail",
    }),
    displayedAds() {
      return this.fastAds === null ? null : this.paginate(this.fastAds);
    },
    filteredDisplayedAdsForSupplier() {
      return this.displayedAds;
    },
    filteredDisplayedAdsForCarrier() {
      return this.displayedAds;
    },
    loading() {
      return this.$store.getters["loading"];
    },
  },
  methods: {
     showLogin() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Kaydırma animasyonu için
      });
      this.$store.commit('toggleLoginDialog', true);
    },
    adsenseAddLoad(){
      console.log("adsense çağırıyor")
        let inlineScript   = document.createElement("script");
        inlineScript.type  = "text/javascript";
        inlineScript.text  = '(adsbygoogle = window.adsbygoogle || []).push({});'
        document.getElementsByTagName('body')[0].appendChild(inlineScript);
    },
    filteredCities() {
      // return this.cityState?.filter((item) => item && item.ID);
    },
    filteredTrailerTypes() {
      // return this.trailerTypes?.filter((item) => item && item.ID);
    },
    formatTooltip(val) {
      return val / 100;
    },
    setPages() {
      // let numberOfPages = Math.ceil(this.ads?.length / this.perPage);
      // this.pages = [];
      // for (let index = 1; index <= numberOfPages; index++) {
      //   this.pages.push(index);
      // }
    },
    paginate(ads) {
      if(this.filters.SearchTerm == "" ||this.filters.SearchTerm.length > 2){
        return ads !== null ? ads.filter(ad => {
          return ad.Content.toLowerCase().includes(this.filters.SearchTerm.toLowerCase())
        }) : null;
      }else{
        return ads;
      }
   
    },
    clearCityId(key) {
      // switch (key) {
      //   case 'warehouse':
      //     this.filters.WarehouseCityId = null
      //     break;
      
      //     case 'delivery':
      //     this.filters.DeliveryCompanyCityId = null
      //     break;
      // }

      // if ((this.filters.WarehouseCityId === "" || this.filters.WarehouseCityId == null) && (this.filters.DeliveryCompanyCityId === "" || this.filters.DeliveryCompanyCityId == null) && this.filters.FilterMe === true) this.filters.FilterMe = false;

    },
    async geolocate() {
   
    },
  },
  watch: {
    filters: {
      handler: async function() {
        // if (this.filters.PaymentType === "") this.filters.PaymentType = null;
        // const filteredCities = this.filteredCities();
        // const warehouseCity = filteredCities.find((x) => x.ID == this.filters.WarehouseCityId);
        // const deliveryCompanyCity = filteredCities.find((x) => x.ID == this.filters.DeliveryCompanyCityId);
        // this.filters.WarehouseLat = parseFloat(warehouseCity?.Latitude);
        // this.filters.WarehouseLon = parseFloat(warehouseCity?.Longitude);
        // this.filters.FilterMe = true;
        // console.log(this.filters.VehicleType);
        
        await lazyDispatch("getFastAdverts", this.filters);
      },
      deep: true,
    },
    ads() {
      this.setPages();
    },
    page() {
      // if (this.page == 0) {
      //   this.page = this.pages.length;
      // } else if (this.page > this.pages.length) {
      //   this.page = 1;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
<style>
.disabledAd {
  background-color: #d9d9d9;
  margin-left: 10px;
  border-radius: 4px;
  padding: 1px 20px;
}

.disabledAd span {
  color: #4d668a;
}

.ad {
  border-radius: 16px;
  background-color: #4d668a;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.adDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 20px;
}
@media (max-width: 600px) {
  .adDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding: 7px;
  }
}
.adDetails span {
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
}

.adDetails span:first-child {
  font-size: 22px;
  margin-left: 0px;
}

.adDetails span:nth-child(2) {
  font-size: 22px;
  margin-left: 0px;
}
.adDetails span:last-child {
  margin-bottom: 0;
}
.hasOffer {
  background-color: #82c0cc;
  margin-left: 10px;
  border-radius: 4px;
  padding: 1px 20px;
}
.passive {
  background-color: #6d6d6d;
  margin-left: 10px;
  border-radius: 4px;
  padding: 1px 20px;
}
@media (max-width: 380px) {
  .ad div:nth-child(2) img {
    margin-right: 0 !important;
  }
}
</style>